import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import axios from 'axios';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  MenuItem,
  Button,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { BASEURL } from '../../utils/constants';
import {
  noMatchErrorMsg,
  reqErrorMsg,
  minLengthErrorMsg,
  maxLengthErrorMsg,
  lengthErrorMsg,
  allowedCharErrorMsg,
  allowedSpclCharErrorMsg
} from '../../utils/helpers';

CallLogForm.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default function CallLogForm(props) {
  const data = props?.data;
  console.log('data : ', data);
  const initialValues = {
    uanNum: data.uan_num || '',
    epfoNum: data.epfo_num || '',
    empName: data.emp_name || '',
    mobile: data.mobile || '',
    cmpnyBtch: data?.cmp_batch_title === '--' ? '' : data.cmp_batch_title || '',
    status: data.status || 'pending',
    remarks: data?.remarks === '--' ? '' : data.remarks || ''
  };
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    uanNum: Yup.string()
      .required(reqErrorMsg)
      .matches(/^[0-9]+$/, allowedCharErrorMsg('numbers'))
      .length(12, lengthErrorMsg(12)),
    epfoNum: Yup.string()
      .required(reqErrorMsg)
      .matches(/^[\w /]*$/, allowedSpclCharErrorMsg('_ /'))
      .min(5, minLengthErrorMsg(5))
      .max(22, maxLengthErrorMsg(22)),
    empName: Yup.string()
      .required(reqErrorMsg)
      .matches(/^[\w .]*$/, allowedSpclCharErrorMsg('_ .')),
    mobile: Yup.string()
      .required(reqErrorMsg)
      .matches(/^[0-9]+$/, allowedCharErrorMsg('numbers'))
      .length(10, lengthErrorMsg(10)),
    cmpnyBtch: Yup.string().matches(/^[\w .]*$/, allowedSpclCharErrorMsg('_ .'))
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      const payload = {
        id: data.id || '',
        uan_num: values.uanNum,
        epfo_num: values.epfoNum,
        emp_name: values.empName,
        mobile: values.mobile,
        cmp_batch_title: values.cmpnyBtch,
        status: values.status,
        remarks: values.remarks
      };
      const url = data.id ? `${BASEURL}${'/call-logs/editLog'}` : `${BASEURL}${'/call-logs/add'}`;
      axios
        .post(url, payload)
        .then((res) => {
          const response = res.data;
          if (response.status) {
            props.handleRefresh();
            props.handleClose();
          } else {
            alert('failed');
          }
        })
        .catch((errorMsg) => console.error(errorMsg));
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          sx={{
            border: 1,
            borderColor: 'grey.500',
            borderRadius: 1,
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 3,
            paddingLeft: 3
          }}
        >
          <Stack
            spacing={3}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <TextField
              fullWidth
              autoComplete="uanNum"
              label="UAN Number"
              value={values.uanNum}
              {...getFieldProps('uanNum')}
              error={Boolean(touched.uanNum && errors.uanNum)}
              helperText={touched.uanNum && errors.uanNum}
            />

            <TextField
              fullWidth
              autoComplete="epfoNum"
              label="EPFO Number"
              value={values.epfoNum}
              {...getFieldProps('epfoNum')}
              error={Boolean(touched.epfoNum && errors.epfoNum)}
              helperText={touched.epfoNum && errors.epfoNum}
            />
            <TextField
              fullWidth
              autoComplete="empName"
              label="Name"
              value={values.empName}
              {...getFieldProps('empName')}
              error={Boolean(touched.empName && errors.empName)}
              helperText={touched.empName && errors.empName}
            />
          </Stack>
          <Stack
            spacing={3}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2, marginTop: 4 }}
          >
            <TextField
              fullWidth
              autoComplete="mobile"
              label="Mobile Number"
              value={values.mobile}
              {...getFieldProps('mobile')}
              error={Boolean(touched.mobile && errors.mobile)}
              helperText={touched.mobile && errors.mobile}
            />

            <TextField
              fullWidth
              autoComplete="company/batch"
              label="Company/ Batch title"
              value={values.cmpnyBtch}
              {...getFieldProps('cmpnyBtch')}
              error={Boolean(touched.cmpnyBtch && errors.cmpnyBtch)}
              helperText={touched.cmpnyBtch && errors.cmpnyBtch}
            />
            <TextField
              select
              fullWidth
              value={values.status}
              {...getFieldProps('status')}
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
            >
              <MenuItem key="pending" value="pending">
                Pending
              </MenuItem>
              <MenuItem key="disposed" value="disposed">
                Disposed
              </MenuItem>
            </TextField>
          </Stack>

          <Stack
            spacing={3}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2, marginTop: 4 }}
          >
            <TextField
              fullWidth
              autoComplete="remarks"
              label="Remarks"
              value={values.remarks}
              {...getFieldProps('remarks')}
              error={Boolean(touched.remarks && errors.remarks)}
              helperText={touched.remarks && errors.remarks}
            />
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ my: 2 }}
          spacing={4}
        >
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>

          <Button autoFocus onClick={props.handleClose} color="error">
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
