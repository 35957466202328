const capitalize2 = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

function capitalize(str) {
  let strings = str.split(' ');
  strings = strings.map((s) => capitalize2(s));
  return strings.join(' ');
}

export { capitalize };

export const noMatchErrorMsg = 'Please provide valid data';
export const allowedSpclCharErrorMsg = (str) => `${'Allowed special characters are '}${str}`;
export const allowedCharErrorMsg = (str) => `${'Only '}${str}${' are allowed'}`;
export const reqErrorMsg = 'Data is required';
export const minLengthErrorMsg = (length) =>
  `${'Min length must be atleast of '}${length}${' characters'}`;
export const maxLengthErrorMsg = (length) =>
  `${'Max length should not exceed '}${length}${' characters'}`;
export const lengthErrorMsg = (length) => `${'Must contain '}${length}${' characters'}`;
