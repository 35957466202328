import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { BASEURL } from '../../../utils/constants';

export default function LoginForm() {
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
    remember: true
  };
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      authenticate(values, actions);
    }
  });

  const authenticate = (values, actions) => {
    const { email, password } = values;
    localStorage.clear();
    const url = `${BASEURL}${'/user/login'}`;
    axios.post(url, { email, password }).then((res) => {
      console.log('Api Response:', BASEURL, res);
      const response = res.data;
      if (response.status) {
        response.data.signedIn = true;
        localStorage.setItem('Auth', JSON.stringify(response.data));
        navigate('/dashboard', { replace: true });
      } else {
        alert('Invalid Credentials');
        // setErrors({submit: 'Invalid Credentials'});
        actions.setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    const Auth = JSON.parse(localStorage.getItem('Auth')) || {};
    if (Auth.signedIn) {
      navigate('/dashboard', { replace: true });
    }
  });

  const authSuccess = async () => {
    await checkAuth();
  };

  const checkAuth = () => {
    const Auth = JSON.parse(localStorage.getItem('Auth')) || {};
    if (Auth.signedIn) {
      navigate('/dashboard', { replace: true });
    } else {
      authSuccess();
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            value={values.email}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={values.password}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
