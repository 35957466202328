import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CallLogForm from '../../components/CallLogs/CallLogForm';

export default function CallLogsModal(props) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose(false);
    setOpen(false);
  };

  const title = props.data?.id ? 'Edit Call Log' : 'Add Call Log';
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={!fullScreen && 'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <CallLogForm
            data={props.data}
            handleClose={handleClose}
            handleRefresh={props.handleRefresh}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
