import PropTypes from 'prop-types';
// material
import { Box, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Stack direction="row" spacing={2}>
      <Box component="img" src="/static/logo.png" sx={{ width: 40, height: 40, ...sx }} />
      <Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }}>
        Kalyani Consultancy Services
      </Typography>
    </Stack>
  );
}
