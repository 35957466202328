// import { filter } from 'lodash';
import _ from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import axios from 'axios';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
//
import USERLIST from '../../_mocks_/user';
import { BASEURL } from '../../utils/constants';
import { capitalize } from '../../utils/helpers';
import CallLogsModal from './CallLogsModal';
import { fDate, fDateTimeSuffix, fTime } from '../../utils/formatTime';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'emp_name', label: 'Name', alignRight: false },
  { id: 'cmp_batch_title', label: 'Company/ Batch', alignRight: false },
  { id: 'uan_num', label: 'UAN', alignRight: false },
  { id: 'epfo_num', label: 'EPF', alignRight: false },
  { id: 'remarks', label: 'Remarks', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'updated_at', label: 'Updated At', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return _.filter(
      array,
      (_log) =>
        _log.emp_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _log.mobile.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _log.cmp_batch_title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _log.uan_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _log.epfo_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _log.remarks.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _log.status.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CallLogs() {
  console.log('USERLIST : ', USERLIST);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [callLogs, setCallLogs] = useState([]);
  const [addCallLog, setAddCallLog] = useState(false);
  const [editCallLog, setEditCallLog] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const url = `${BASEURL}${'/call-logs/list'}`;
    axios.get(url).then((res) => {
      const response = res.data;
      console.log('logs : ', response);
      if (response.status) {
        setCallLogs(response.data);
      } else {
        alert('Failed to fetch data.');
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = callLogs.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const newLog = (val) => {
    setSelectedLog({});
    setAddCallLog(val);
  };

  const handleEdit = (row) => {
    console.log('Edited ID : ', row.emp_name);
    setSelectedLog(row);
    setAddCallLog(true);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredLogs = callLogs.length
    ? applySortFilter(callLogs, getComparator(order, orderBy), filterName)
    : [];

  console.log('filteredLogs', filteredLogs);

  const isLogNotFound = filteredLogs.length === 0;

  return (
    <Page title="Call Logs | Kalyani Consultancy Services">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Call Logs
          </Typography>
          <Button
            variant="contained"
            onClick={() => newLog(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Call Log
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={callLogs.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredLogs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const avatarUrl = '/static/mock-images/avatars/avatar_1.jpg';
                      const {
                        id,
                        emp_name,
                        mobile,
                        cmp_batch_title,
                        uan_num,
                        epfo_num,
                        remarks,
                        status,
                        created_at,
                        updated_at
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {capitalize(emp_name)}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="caption" noWrap>
                                {mobile}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {cmp_batch_title === '--' ? '' : cmp_batch_title}
                          </TableCell>
                          <TableCell align="left">{uan_num}</TableCell>
                          <TableCell align="left">{epfo_num}</TableCell>
                          <TableCell align="left">{remarks === '--' ? '' : remarks}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(status === 'pending' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="p" noWrap>
                                {fDate(updated_at)}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="caption" noWrap>
                                {fTime(updated_at)}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu data={row} handleEdit={handleEdit} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isLogNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={callLogs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {addCallLog && (
          <CallLogsModal data={selectedLog} handleClose={newLog} handleRefresh={fetchData} />
        )}
      </Container>
    </Page>
  );
}
